
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { AppStore } from '@interface/store/App';

import PinGhostSvg from '@svg/PinGhost.vue';
import ClockSvg from '@svg/Clock.vue';
import CalendarSmall from '@svg/CalendarSmall.vue';
import MyNightButton from '@part/elements/MyNightButton.vue';
import Calendar from "@svg/Calendar.vue";
/* ----------------------------------------
    Component
---------------------------------------- */
@Component({
  components: {CalendarSmall, PinGhostSvg, ClockSvg, MyNightButton },
  props: {
    data: {
      type: Object,
      required: true
    },
    // my night events/exhibitions have slightly different logic.
    // date keys come differently
    myNightRouteEl: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState(['activeHearts', 'eventType'])
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class CardMeta extends Vue { }
