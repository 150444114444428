
/* ----------------------------------------
    Imports
---------------------------------------- */
import { Component, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  props: {
    data: {
      type: Object,
      required: true
    },
    linkDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Bekijk'
    }
  },
  methods: {
    shortenText(str, n) {
      if (str.length > n) return str.substring(0, n) + '...';
      return str;
    }
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class CardLong extends Vue {}
