
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import CardMeta from '@part/elements/CardMeta.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    CardMeta
  },
  props: {
    noFloats: {
      type: Boolean,
      required: false,
      default: false
    },
    background: {
      type: Boolean,
      required: false,
      default: false
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    square: {
      type: Boolean,
      required: false,
      default: false
    },
    data: {
      type: Object,
      required: true
    },
    // my night events/exhibitions have slightly different logic.
    // date keys come differently
    myNightRouteEl: {
      type: Boolean,
      required: false,
      default: false
    }
  },

})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class CardDefault extends Vue { }
